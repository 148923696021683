.imageViewerViewport.empty ~ .ViewportOverlay {
  display: none;
}
.ViewportOverlay {
  color: hsl(var(--highlight));
}
.ViewportOverlay .overlay-element {
  position: absolute;
  font-weight: 400;
  text-shadow: 1px 1px #000;
  pointer-events: none;
}
.overlay-top {
  top: 2.15rem;
}
.overlay-bottom {
  bottom: 1rem;
}

.overlay-text {
  text-shadow: 0.8px 0.8px 0.5px rgba(0, 0, 0, 0.65);
}
